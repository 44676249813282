import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'
import FormVacancy from 'components/elements/Static/FormVacancy'

interface VacancyContextProps {
  title?: string
}

interface PageTemplateProps {
  data: {
    page: {
      path?: string
      title?: string
      seo: any
      flexContent: any
    }
  }
  location?: any
  prefix?: string
}

export const VacancyContext = React.createContext<VacancyContextProps>({
  title: '',
})

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { flexContent, seo, title },
  },
  location = {},
  prefix = 'Vacancy_Flexcontent',
}) => (
  <VacancyContext.Provider value={{ title }}>
    <Layout location={location}>
      <SEO seo={seo} />
      <FlexContentHandler
        prefix={prefix}
        fields={flexContent}
        location={location}
      />
      <FormVacancy vacancyTitle={title} />
    </Layout>
  </VacancyContext.Provider>
)

export const pageQuery = graphql`
  query vacancyById($pageId: String!) {
    page: wpVacancy(id: { eq: $pageId }) {
      ...generalVacancyFragment
    }
  }
`

export default PageTemplate
