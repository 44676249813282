import React from 'react'

// Third party
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import FullCircleSecondary from 'components/elements/Vectors/FullCircleSecondary'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const Section = styled.section`
  position: relative;
  z-index: 2;
  margin-top: 9rem;
  padding-bottom: 16rem;
`

const BackgroundWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  top: -250px;
  left: -60px;

  @media (max-width: 1399.98px) {
    left: -140px;

    & svg {
      width: 700px;
      height: 700px;
    }
  }

  @media (max-width: 1199.98px) {
    left: -140px;
  }

  @media (max-width: 991.98px) {
    opacity: 0.15;
  }
`

const Content = styled(ParseContent)`
  z-index: 5;
  @media (min-width: 992px) {
    padding-right: 10rem;
  }
`

const TextCircle: React.FC<TextProps> = ({ fields }) => (
  <Section>
    <div className="container py-5">
      <div className="row justify-content-end">
        <div className={`col-lg-${fields.colwidth}`}>
          <Content content={fields.description} className="position-relative" />
        </div>
      </div>
    </div>
    <BackgroundWrapper>
      <FullCircleSecondary image={fields.image} />
    </BackgroundWrapper>
  </Section>
)

export default TextCircle
