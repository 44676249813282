import React from 'react'

// Third party
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlockPrimary from 'components/elements/Blocks/BlockPrimary'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const Section = styled.section`
  position: relative;
  z-index: 2;
`

const SideBlock = styled(BlockPrimary)`
  padding: 2rem 1rem;
  border-radius: 50px;

  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  @media (min-width: 576px) {
    margin-left: 4rem;
    margin-top: 6rem;
  }
`

const Content = styled(ParseContent)`
  & h2 {
    font-size: ${({ theme }) => theme.font.size.huge};
    opacity: 0.4;
  }

  & p {
    line-height: ${({ theme }) => theme.font.lineheight.small};
  }

  & img {
    object-fit: contain !important;
    margin-bottom: 2rem !important;
  }
`

const TextSideBlock: React.FC<TextProps> = ({ fields }) => {
  return (
    <Section>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="row">
              <div className={`col-lg-${fields.colwidth} mb-5 mb-sm-0`}>
                <ParseContent content={fields.description} />
              </div>
              <div
                className={`col-lg-${
                  fields.colwidth ? 12 - fields.colwidth : 6
                } `}
              >
                <SideBlock className="d-flex flex-column align-items-center">
                  <Content
                    content={fields.seconddescription}
                    className="mt-3"
                  />
                </SideBlock>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default TextSideBlock
