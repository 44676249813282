import React from 'react'
import styled from 'styled-components'

// Elements
import BlockPrimary from 'components/elements/Blocks/BlockPrimary'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'

// Images
import BackgroundCircle from 'img/backgroundcircle.inline.svg'
import Upload from 'img/upload.svg'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'

const Section = styled.div`
  position: relative;

  @media (max-width: 1439px) {
    padding-top: 3rem;
  }
`

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 2rem;
    position: relative;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    position: relative;

    width: 100%;
    border-color: hsl(0, 0%, 80%);
    border-radius: 50px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    padding-left: 13px;
    padding-right: 13px;
  }

  & .form-duuf-textarea {
    min-height: 245px;
    border-radius: 12px;
  }

  & .form-duuf-file-button {
    width: 100%;

    &:hover {
      opacity: 0.85;
    }

    & button {
      position: relative;
      border-radius: 24px !important;
      background-color: ${({ theme }) => theme.color.primary};
      height: 100%;
      min-height: 39px;
      width: 100%;
      max-width: 200px;

      &::before {
        content: 'Bestand selecteren';
        position: absolute;
        top: 9px;
        left: 12px;
        font-size: 14px;
        color: ${({ theme }) => theme.color.secondary};
      }

      &::after {
        content: '';
        background-image: url(${Upload});
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 4px;
        margin-right: 5px;
        height: 20px;
        width: 20px;
        position: absolute;
        right: 5px;
        top: 5px;
      }
    }
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-label {
    padding-bottom: 0.6rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  .form-duuf-error-consent {
    position: absolute;

    @media (min-width: 576px) {
      top: 53px !important;
    }

    @media (max-width: 575.98px) {
      top: 22px !important;
      margin-top: 50px;
    }
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    position: absolute;
    right: 10px;
    top: 5px;
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    font-size: ${({ theme }) => theme.font.size.huge};
    color: ${({ theme }) => theme.color.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const BackgroundWrapper = styled.div`
  position: absolute;
  pointer-events: none;
  width: 100%;
  top: -600px;
  left: 0;
  z-index: -1;
  transform: rotate(180deg);
`

interface FormVacancyProps {
  vacancyTitle?: string
}

const FormVacancy: React.FC<FormVacancyProps> = ({ vacancyTitle }) => (
  <Section className="mb-5 pb-5" id="go-to-form">
    <BackgroundWrapper>
      <BackgroundCircle />
    </BackgroundWrapper>
    <div className="container py-5">
      <StyledFormDuuf
        id={3}
        className="mt-5"
        generate={false}
        defaultFields={{ input_1: vacancyTitle }}
      >
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <BlockPrimary>
              <div className="px-4">
                <h2 className="font-weight-bold mb-4">Solliciteer direct!</h2>

                <div className="row">
                  <div className="col-lg-7">
                    <FormDuufGroup showIds={[2, 3, 4, 5]} />
                  </div>
                  <div className="col-lg-5">
                    <FormDuufGroup showIds={[6]} />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <FormDuufSubmit />
              </div>
            </BlockPrimary>
          </div>
        </div>
      </StyledFormDuuf>
    </div>
  </Section>
)

export default FormVacancy
