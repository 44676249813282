import React from 'react'

// Third party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlockPrimary from 'components/elements/Blocks/BlockPrimary'
import Share from 'components/elements/Misc/Share'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'
import { VacancyContext } from 'templates/vacancy'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

const Section = styled.section`
  @media (min-width: 1200px) {
    margin-top: 2rem;
    min-height: 70vh;
  }
  @media (max-width: 1199px) {
    margin-top: 13rem;
  }
`

const Image = styled(Plaatjie)`
  position: absolute;
  z-index: 2;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  object-fit: cover;

  @media (min-width: 1200px) {
    left: 10px;
    top: 90px;
    width: 372px;
    height: 372px;
  }

  @media (max-width: 1199px) {
    right: 10px;
    top: -180px;
    width: 250px;
    height: 250px;
  }
  @media (max-width: 575px) {
    width: 200px;
    height: 200px;
  }
  @media (max-width: 389px) {
    width: 140px;
    height: 140px;
    top: -120px;
  }
`

const ShareWrapper = styled.div`
  position: absolute;
  z-index: 4;

  @media (min-width: 1200px) {
    top: 500px;
    left: -50px;
  }

  @media (max-width: 1199px) {
    top: -100px;
    left: 0;
  }
`

const Content = styled(ParseContent)`
  @media (min-width: 992px) {
    & h2 {
      font-size: ${({ theme }) => theme.font.size.huge};
    }

    & h3 {
      font-size: ${({ theme }) => theme.font.size.large};
    }
  }

  @media (min-width: 576px) {
    padding: 3rem 3rem 3rem 5rem;
  }
`

const Sidebar = styled(BlockPrimary)``

const TextPost: React.FC<TextProps> = ({ fields, location }) => {
  const context = React.useContext(VacancyContext)

  let heading = 'Deel dit artikel'

  const headingStyle = {
    paddingBottom: '8.7rem',
  }

  const componentStyle = {
    justifyContent: 'end',
    colWidth: '9',
  }

  if (location.pathname.includes('vacatures')) {
    heading = 'Deel deze vacature'
    headingStyle.paddingBottom = '12.6rem'
  }

  if (location.pathname.includes('cases')) {
    heading = 'Deel deze case'
    headingStyle.paddingBottom = '9.6rem'
  }

  if (fields.subtype === 'extracol') {
    componentStyle.justifyContent = 'start'
    componentStyle.colWidth = '8'
  }

  return (
    <Section className="mb-5 pb-lg-5">
      <div className="container position-relative">
        {fields.subtype === 'image' && fields.image && (
          <Image image={fields.image} alt="" />
        )}
        <div className={`row justify-content-${componentStyle.justifyContent}`}>
          <div
            className={`position-relative col-xl-${componentStyle.colWidth}`}
          >
            <ShareWrapper className="d-flex flex-row flex-xl-column ms-3 ms-lg-0">
              <Share
                heading={heading}
                headingStyle={headingStyle}
                location={location}
                title=""
                body=""
                quote=""
                className="d-flex flex-row flex-xl-column"
              />
            </ShareWrapper>
            <BlockPrimary className="py-4 px-3">
              <Content content={fields.description} />
            </BlockPrimary>
          </div>
          {fields.subtype === 'extracol' && fields.seconddescription && (
            <div className="col-lg-4">
              <Sidebar className="px-4">
                <Content
                  content={fields.seconddescription}
                  className="px-0 mb-4 mb-lg-0"
                />
                <p>
                  Heb je vragen, wil je meer weten of direct met ons
                  kennismaken? Dat kan via Whatsapp.
                </p>
                <ButtonPrimary isCustom>
                  <a
                    href={`https://wa.me/31620393776?text=Hi%20Stein,%20Graag%20zou%20ik%20meer%20te%20weten%20komen%20over%20de%20functie%20van%20${context.title}%20bij%20Open%20ICT%20in%20Amersfoort`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    App ons
                  </a>
                </ButtonPrimary>
              </Sidebar>
            </div>
          )}
        </div>
      </div>
    </Section>
  )
}

export default TextPost
