import React from 'react'
import styled from 'styled-components'
import {
  WhatsappShareButton,
  FacebookShareButton,
  EmailShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'

// Images
import Facebook from 'img/sharefacebook.inline.svg'
import LinkedIn from 'img/sharelinkedin.inline.svg'
import Mail from 'img/sharemail.inline.svg'
import WhatsApp from 'img/sharewhatsapp.inline.svg'
import Twitter from 'img/sharetwitter.inline.svg'

const ShareContainer = styled.div`
  & h2 {
    color: ${({ theme }) => theme.color.primary};
    font-size: ${({ theme }) => theme.font.size.large};
    font-weight: ${({ theme }) => theme.font.weight.bold};

    @media (min-width: 1200px) {
      transform: rotate(-90deg);
    }

    @media (max-width: 1199px) {
      display: none;
    }
  }

  & svg {
    width: 30px;
    height: 30px;
    overflow: visible;

    &:hover {
      opacity: 0.9;
    }
  }
`

interface ShareProps {
  heading: string
  headingStyle: {
    paddingBottom?: string
  }
  location: any
  title: string
  body: string
  quote?: string
  className?: string
}

const Share: React.FC<ShareProps> = ({
  heading,
  headingStyle,
  location,
  title,
  body,
  quote,
  className = '',
}) => {
  const shareUrl = location ? location.href : ''

  const removeHTML = quote?.replace(/<\/?[^>]+(>|$)/g, '')
  const removeQuote = removeHTML?.replace('&#8220;', '"')
  const cleanQuote = removeQuote?.replace('&#8221;', '"')

  return (
    <ShareContainer className={className}>
      <div>
        <LinkedinShareButton
          url={shareUrl}
          title={title}
          summary={body}
          source={shareUrl}
          className="mb-3 me-1"
        >
          <div className="position-relative d-flex flex-column justify-content-center align-items-center">
            <div>
              <LinkedIn />
            </div>
          </div>
        </LinkedinShareButton>
      </div>
      <div>
        <FacebookShareButton
          url={shareUrl}
          quote={cleanQuote}
          title={title}
          className="mb-3 me-1"
        >
          <div className="position-relative d-flex flex-column justify-content-center align-items-center">
            <div>
              <Facebook />
            </div>
          </div>
        </FacebookShareButton>
      </div>
      <div>
        <TwitterShareButton url={shareUrl} title={title} className="mb-3 me-1">
          <div className="position-relative d-flex flex-column justify-content-center align-items-center">
            <div>
              <Twitter />
            </div>
          </div>
        </TwitterShareButton>
      </div>
      <div>
        <EmailShareButton
          url={shareUrl}
          subject={title}
          body={body}
          className="mb-3 me-1"
        >
          <div className="position-relative d-flex flex-column justify-content-center align-items-center">
            <div>
              <Mail />
            </div>
          </div>
        </EmailShareButton>
      </div>
      <div className="mb-3">
        <WhatsappShareButton url={shareUrl} title={title} className="mb-3">
          <div className="position-relative d-flex flex-column justify-content-center align-items-center">
            <div>
              <WhatsApp />
            </div>
          </div>
        </WhatsappShareButton>
      </div>
      <h2 style={{ paddingBottom: headingStyle?.paddingBottom }}>{heading}</h2>
    </ShareContainer>
  )
}

export default Share
