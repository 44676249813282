import React from 'react'

// Third party
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

const Section = styled.section<{ bgColor: string }>`
  position: relative;
  z-index: 2;

  & tr {
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column !important;
    }
  }

  & td {
    @media (max-width: 767px) {
      margin-top: 1rem;
      width: 100% !important;
    }
  }

  ${(props) =>
    props.bgColor === 'contrast' &&
    css`
      background-color: ${({ theme }) => theme.color.primary};

      & h2 {
        font-size: ${({ theme }) => theme.font.size.huge};
      }
    `}
`

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Text: React.FC<TextProps> = ({ fields }) => (
  <Section
    bgColor={fields.backgroundcolor || 'default'}
    className={`${fields.backgroundcolor === 'contrast' ? 'mb-5' : ''}`}
  >
    <div className="container py-5">
      <div
        className={`row ${
          fields.backgroundcolor !== 'default'
            ? 'justify-content-center'
            : 'justify-content-start'
        }`}
      >
        <div className={`col-lg-${fields.colwidth}`}>
          <ParseContent content={fields.description} />
        </div>
      </div>
    </div>
  </Section>
)

export default Text
