import React from 'react'

// Components
import Text from 'components/flex/Text/Text'
import TextBigHeadings from 'components/flex/Text/TextBigHeadings'
import TextCta from 'components/flex/Text/TextCta'
import TextSideBlock from 'components/flex/Text/TextSideBlock'
import TextCircle from 'components/flex/Text/TextCircle'
import TextPost from 'components/flex/Text/TextPost'

export interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
  location?: any
}

interface PostProps {
  [key: string]: any
}

const TextShell: React.FC<TextProps> = ({ fields, location = {} }) => {
  const types: PostProps = {
    default: Text,
    cta: TextCta,
    sideblock: TextSideBlock,
    circle: TextCircle,
    bigheadings: TextBigHeadings,
    post: TextPost,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default TextShell
