import React from 'react'

const FullCircleSecondary = ({ image }: any) => (
  <svg
    width="779"
    height="1038"
    viewBox="0 0 779 1038"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <mask
      id="maskfullcirclesecondary"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="-259"
      y="0"
      width="1038"
      height="1038"
    >
      <g clipPath="url(#clipfullcirclesecondary)">
        <path
          d="M328.936 299.229C364.419 310.27 396.68 329.785 422.93 356.088C466.094 399.347 490.335 457.962 490.335 519.072C490.335 580.182 466.094 638.797 422.93 682.056C396.68 708.359 364.419 727.874 328.936 738.915L328.936 879.391C401.146 865.741 467.55 830.604 519.455 778.581C662.552 635.484 662.552 402.66 519.455 259.563C467.549 207.542 401.145 172.406 328.936 158.753L328.936 299.229Z"
          fill="#082E53"
        />
        <path
          d="M192.492 739.331C156.431 728.378 123.633 708.684 97.016 682.002C53.8524 638.743 29.6114 580.128 29.6114 519.018C29.6114 457.908 53.8525 399.293 97.016 356.034C123.634 329.352 156.431 309.659 192.492 298.705L192.529 158.446C119.751 171.882 52.7806 207.136 0.50915 259.527C-142.588 402.624 -142.588 635.448 0.509154 778.545C52.7807 830.936 119.751 866.19 192.529 879.626L192.492 739.331Z"
          fill="#FCCF14"
        />
      </g>
    </mask>
    <g mask="url(#maskfullcirclesecondary)">
      <rect
        x="-104"
        y="153"
        width="1017"
        height="1150"
        fill="url(#patternfullcirclesecondary)"
      />
    </g>
    <defs>
      <pattern
        id="patternfullcirclesecondary"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use
          xlinkHref="#imagefullcirclesecondary"
          transform="translate(-0.0023552 -0.00261097) scale(0.000152584)"
        />
      </pattern>
      <clipPath id="clipfullcirclesecondary">
        <rect
          width="733.977"
          height="733.977"
          fill="white"
          transform="translate(260) rotate(45)"
        />
      </clipPath>
      <image
        id="imagefullcirclesecondary"
        width="6619"
        height="4329"
        xlinkHref={image?.localFile?.publicURL}
      />
    </defs>
  </svg>
)

export default FullCircleSecondary
