import React from 'react'

// Third party
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const Section = styled.section`
  position: relative;
  z-index: 2;
  background-color: #f4f4f4;
  margin-bottom: 6rem;
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    opacity: 0.1;

    @media (min-width: 992px) {
      font-size: 150px;
      line-height: 150px;
    }
    @media (max-width: 991px) {
      font-size: 50px;
      line-height: 50px;
    }
  }
`

const TextBigHeadings: React.FC<TextProps> = ({ fields }) => (
  <Section>
    <div className="container py-5">
      <div className="row">
        <div className={`col-lg-${fields.colwidth}`}>
          <Content content={fields.description} />
        </div>
        <div
          className={`col-lg-${
            fields.colwidth ? 12 - fields.colwidth : 6
          } mt-5 pt-4`}
        >
          <Content content={fields.seconddescription} />
        </div>
      </div>
    </div>
  </Section>
)

export default TextBigHeadings
