import React from 'react'

// Third party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const Section = styled.section`
  font-family: ${({ theme }) => theme.font.family.primary};
  position: relative;
  z-index: 2;
`

const Cta = styled.div`
  background-color: #f4f4f4;
  padding: 2rem 1rem;
  border-radius: 50px;
  margin-left: 4rem;

  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`

const Image = styled(Plaatjie)`
  border-radius: 50%;
  width: 139px;
  height: 139px;
`

const Content = styled(ParseContent)`
  & p {
    line-height: ${({ theme }) => theme.font.lineheight.small};
  }
`

const TextCta: React.FC<TextProps> = ({ fields }) => (
  <Section className="mb-5 pb-lg-5">
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <div className="row">
            <div className={`col-lg-${fields.colwidth} mb-4`}>
              <ParseContent content={fields.description} />
            </div>
            <div
              className={`col-lg-${
                fields.colwidth ? 12 - fields.colwidth : 6
              } `}
            >
              <Cta className="d-flex flex-column align-items-center">
                <h2 className="text-center mb-5">{fields.cta?.title}</h2>
                <Image image={fields.cta?.image} alt="" />
                <Content content={fields.cta?.description} className="mt-3" />
              </Cta>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>
)

export default TextCta
